import '../vendor/packery.pkgd';
import '../utils/jquery/lazy-bootstrap';
import('jquery-ujs');
import * as sessions from '../icmib/sessions';
import * as tiles from '../icmib/tiles';
import cookiePolicyNotice from '../utils/cookiePolicyNotice';

ICMIB._IS_DROPDOWN_LOADED_ICMIB = false;
ICMIB.cookiePolicyNotice = cookiePolicyNotice;
ICMIB.sessions = sessions;
ICMIB.tiles = tiles;

$('body').removeClass('no-js');

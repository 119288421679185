const chunk = require('lodash/chunk');

var packer = null;

export function init(el) {
  el = $(el)[0];

  if (ICMIB.tileIDs) {
    return fetchTiles(el);
  } else {
    return initLayout(el);
  }
}

function initLayout(el) {
  const $el = $(el);

  return initPackery(el, function(pckr) {
    // recalculate layout when leaderboard accordion is changed
    $('#tiles').on('hidden', '.leaderboard', () => pckr.layout());

    if (ICMIB.admin_tiles != null) {
      ICMIB.admin_tiles.init($el, pckr);
    }

    packer = pckr;
    return packer;
  });
}

export function pack() {
  return packer && packer.layout();
}

export function initPackery(el, cb) {
  const $el = $(el);
  $el.packery({
    itemSelector: '.til',
    stamp: '.stamp',
    columnWidth: '.til-width',
    gutter: '.til-gutter',
  });
  $el.addClass('packed');
  $el.css({ opacity: 1 });
  const pack = $el.data('packery');
  return cb && cb(pack);
}

function fetchTiles(el) {
  if (!ICMIB.tileIDs.length) {
    $('#tiles-loading').remove();
    return initLayout(el);
  } else {
    const pageSize = 20;
    const chunkedTiles = chunk(ICMIB.tileIDs, pageSize);
    const fetchedTiles = {};

    const handleTileFetched = function(data, index) {
      fetchedTiles[index] = data;
      const chunksLoaded = Object.keys(fetchedTiles).length;
      $('#tiles-progress').width(
        chunksLoaded / chunkedTiles.length * 100 + '%'
      );
      if (chunksLoaded === chunkedTiles.length) {
        return appendTiles();
      }
    };

    var appendTiles = function() {
      const chunkKeys = Object.keys(fetchedTiles).sort();
      const tiles = chunkKeys.reduce(
        (result, key) => result.concat(fetchedTiles[key].tiles),
        []
      );
      const html = tiles.map(
        tile => $($.trim(tile)).filter((i, el) => $(el).is('.til'))[0]
      );
      $('#new-tile').before(html);
      initLayout(el);
      return $('#tiles-loading').remove();
    };

    return chunkedTiles.forEach((ids, index) =>
      $.ajax({
        url: ICMIB.edit_tiles_path,
        data: { ids: [ids] },
        success(data) {
          return handleTileFetched(data, index);
        },
        error: handleTileFetchError,
      })
    );
  }
}

function handleTileFetchError(error) {
  return console.warn('Tile fetch failed', error);
}
